<template>
  <section class="container">
    <div
      class="card-border mb-40"
      v-if="klineDataList && klineDataList.length > 0"
    >
      <h3>{{$t('BTokensMarketCap')}}</h3>
      <h1>
        ${{ webUtil.addCommas(klineDataList[klineDataList.length - 1]) }}
        <span v-if="marketPerChange >= 0" class="color-up"
          >+{{ marketPerChange }}%</span
        >
        <span v-else class="color-down">{{ marketPerChange }}%</span>
      </h1>
      <KlinePart
        :timeList="klineTimeList"
        :dataList="klineDataList"
        v-if="klineDataList && klineDataList.length > 2"
      />
    </div>
    <article class="mb-40">
      <ul class="title flex-between">
        <li class="search-box">
          <input
            type="text"
            class="search"
            :placeholder="$t('SearchTokenName')"
            v-model="searchInfo"
            @input="debouncedSearchToken"
          />
          <img
            src="@/assets/img/icon/close.png"
            width="16"
            class="close-btn"
            v-show="searchInfo"
            @click="emptySearch"
          />
        </li>
        <li class="flex-between">
          <p class="sort-type mr-6">
            <span
              v-for="(t, i) in types"
              :key="i"
              :class="{ active: selectedType == t.ID }"
              @click="getListByType(t.ID)"
            >
              <i v-if="i != 0" class="color-grey"> / </i>{{ t.name }}</span
            >
          </p>
          <p class="sort-type">
            <span
              v-for="(f, i) in filterType"
              :key="i"
              :class="{ active: selectedFilter == f }"
              @click="selectedFilter = f"
            >
              <i v-if="i != 0" class="color-grey"> / </i>{{ f }}</span
            >
          </p>
        </li>
      </ul>
      <main class="table-responsive">
        <table v-if="list && list.length > 0">
          <thead>
            <tr>
              <th>#</th>
              <th
                v-for="(item, i) in sortType"
                :key="i"
                :class="{
                  active: sortByWho == item.type,
                  'pointer': item.type,
                }"
                @click="sortList(item)"
              >
                <p :class="{ 'flex-align-center': i != sortType.length - 1 }">
                  <i v-html="item.name"></i>
                  <img
                    v-if="item.order"
                    :src="item.order == 'desc' ? downImg : upImg"
                    width="12"
                  />
                </p>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in list" :key="item.ID">
              <td>{{ 10 * (page - 1) + (i + 1) }}</td>
              <td v-if="item.projectID">
                <router-link class="text-underline" :to="'/project/'+item.projectID">{{ item.symbol ? item.symbol : "--" }}</router-link>
              </td>
              <td v-else>{{ item.symbol ? item.symbol : "--" }}</td>
              <td>
                {{ webUtil.formatNumToShort(item.marketCapacity) }}
              </td>
              <td>
                <p
                  v-if="selectedFilter == '24H'"
                  :class="[
                    item.marketPerChange >= 0 ? 'color-up' : 'color-down',
                  ]"
                >
                  <i v-if="item.marketPerChange >= 0">+</i
                  >{{ webUtil.forPct(item.marketPerChange) }}%
                </p>
                <p
                  v-else
                  :class="[
                    item.marketPerChangeWeek >= 0 ? 'color-up' : 'color-down',
                  ]"
                >
                  <i v-if="item.marketPerChangeWeek >= 0">+</i
                  >{{ webUtil.forPct(item.marketPerChangeWeek) }}%
                </p>
              </td>
              <td>
                {{ webUtil.formatNumToShort(item.usedAmount) }}
              </td>
              <td>
                <p
                  v-if="selectedFilter == '24H'"
                  :class="[
                    item.amountPerChange >= 0 ? 'color-up' : 'color-down',
                  ]"
                >
                  <i v-if="item.amountPerChange >= 0">+</i
                  >{{ webUtil.forPct(item.amountPerChange) }}%
                </p>
                <p
                  v-else
                  :class="[
                    item.amountPerChangeWeek >= 0 ? 'color-up' : 'color-down',
                  ]"
                >
                  <i v-if="item.amountPerChangeWeek >= 0">+</i
                  >{{ webUtil.forPct(item.amountPerChangeWeek) }}%
                </p>
              </td>
              <td v-if="item.list && item.list.length > 1">
                <sparkline
                  :indicatorStyles="false"
                  :tooltipStyles="null"
                  width="90"
                >
                  <sparklineLine
                    :data="item.list"
                    :limit="item.list.length"
                    :styles="{ stroke: '#e6007a' }"
                    :refLineStyles="null"
                  />
                </sparkline>
              </td>
              <td v-else></td>
              <td class="date">{{ item.addDate.slice(0, 10) }}</td>
              <td class="min-width" v-if="item.contractAddress-0==0">--</td>
              <td class="min-width" v-else>
                <a :href="'https://bscscan.com/token/'+item.contractAddress" target="_blank" rel="noopener noreferrer"><img src="@/assets/img/logo/bsc.png" width="20"></a>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="null" v-else>{{$t('NoInformation')}}</div>
      </main>
      <Pagination
        v-if="list && list.length > 0"
        :total="total"
        :page="page"
        :limit="limit"
        @emitPage="getList"
      />
    </article>
    <p class="text-center color-grey">
      <span>{{$t('WhatIsBToken')}}</span>
      <a class="text-underline color-grey" href="https://www.binance.org/en/btokens" target="_blank" rel="noopener noreferrer">https://www.binance.org/en/btokens</a>
    </p>
  </section>
</template>
<script>
import Pagination from "@/components/Pagination";
import KlinePart from "@/components/KlinePart.vue";
export default {
  components: { Pagination, KlinePart },
  data() {
    return {
      klineTimeList: [],
      klineDataList: [],
      marketPerChange: 0,
      filterType: ["24H", "7D"],
      selectedFilter: "24H",
      types:[{
        name:"All",
        ID:2,
      },{
        name:"Peg",
        ID:1,
      },{
        name:"Native",
        ID:0,
      }],
      selectedType:2,
      sortByWho: "marketCapacity",
      sortOrder: "desc",
      list: null,
      total: 0,
      limit: 10, //每页几条数据
      page: 1,
      searchInfo: "",
    };
  },
  computed: {
    // sort表示要排序的字段，支持 symbol, marketCapacity,usedAmount,marketPerChange,amountPerChange,marketPerChangeWeek,amountPerChangeWeek,createDate
    // order=asc升序 order=desc降序
    sortType() {
      return [
        {
          name: this.$t('Token'),
          type: "symbol",
          order: "desc",
        },
        {
          name: this.$t("UsedMarketCap"),
          type: "marketCapacity",
          order: "desc",
        },
        {
          name: this.$t('MarketCapChange'),
          type:
            this.selectedFilter == "24H"
              ? "marketPerChange"
              : "marketPerChangeWeek",
          order: "desc",
        },
        {
          name: this.$t('UsedAmount'),
          type: "usedAmount",
          order: "desc",
        },
        {
          name: this.$t('AmountChange'),
          type:
            this.selectedFilter == "24H"
              ? "amountPerChange"
              : "amountPerChangeWeek",
          order: "desc",
        },
        {
          name: this.$t('Last7DaysAmt'),
        },
        {
          name: this.$t('AddDate'),
          type: "createDate",
          order: "desc",
        },
        {
          name: this.$t('Contract'),
        },
      ];
    },
  },
  created() {
    this.getList();
    this.getKlineData();
    this.debouncedSearchToken = this.lodash.debounce(this.searchToken, 500);
  },
  methods: {
    getKlineData() {
      this.klineTimeList = null;
      this.klineDataList = null;
      this.axios
        .get(this.domain + "getBtokensMarketCap")
        .then((res) => {
          if (res.data.success) {
            this.marketPerChange = this.webUtil.forPct(
              res.data.data.percentChange
            );
            let dataList = res.data.data.list;
            if (dataList) {
              this.klineTimeList = dataList.map((v) => v.showDate.slice(5, 11));
              this.klineDataList = dataList.map((v) => v.total);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    sortList(item) {
      if (!item.type) {
        return false;
      }
      if (this.sortByWho == item.type) {
        item.order = item.order == "desc" ? "asc" : "desc";
      } else {
        this.sortByWho = item.type;
      }
      this.sortOrder = item.order;

      this.getList();
    },
    getListByType(type){
      this.selectedType = type;
      this.getList();
    },
    getList(page = 1) {
      this.page = page;

      this.$loading(1);
      // order=asc升序 order=desc降序
      let URL =
        this.domain +
        "getBtokensList?sort=" +
        this.sortByWho +
        "&type=" +this.selectedType+
        "&order=" +
        this.sortOrder +
        "&limit=" +
        this.limit +
        "&page=" +
        page;
      if (this.searchInfo) {
        URL += "&key=" + this.searchInfo;
      }
      this.axios
        .get(URL)
        .then((res) => {
          this.$loading(0);
          if (res.data.success) {
            this.list = res.data.data.list;
            this.total = res.data.data.total;
          }
        })
        .catch((err) => {
          console.log(err);
          this.$loading(0);
        });
    },
    searchToken() {
      this.$loading(1);
      this.searchInfo = this.searchInfo.trim();
      this.getList();
    },
    emptySearch() {
      //清空搜索框
      this.searchInfo = "";
      this.getList();
    },
  },
};
</script>
<style scoped>
.search-box img {
  margin: 0;
}
.min-width{
  min-width: 30px;
}
.text-underline{
  color: #000;
}
th:nth-child(n+3),td:nth-child(n+3){
  text-align: right;
}
th:nth-child(n+3) .flex-align-center{
  justify-content: flex-end;
}
td.date{
  min-width: 100px;
}
@media(max-width:788px){
  .title{
    flex-wrap: wrap;
  }
  .title li{
    width: 100%;
    margin-top: 16px;
  }
  .title li:first-child{
    margin: 0;
  }
}
</style>
